<template>
  <div style="height: 300px">
    <a-table-f-api
      v-if="phone"
      :api="api"
      :model="model"
      :searchable="false"
      :defaults="{ filters: { phone: phone } }"
    />
  </div>
</template>

<script>
import genModelBase from "@/share/components/libs/genModelBase";

export default {
  mixins: [genModelBase],
  props: {
    phone: String,
  },
  data() {
    return {
      data: [],
    };
  },
  computed: {
    api() {
      return this.$root.api.cardCalls;
    },
    model() {
      return this.generateModel("index", "call_log");
    },
  },
};
</script>